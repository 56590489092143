<template>
  <v-card color="basil">
    <v-card-title class="text-center justify-center py-6">
      <h1 class="font-weight-bold text-h2 basil--text">Action</h1>
    </v-card-title>

    <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item">
        <v-card color="basil" flat>
          <v-card-text>{{ text }}</v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items> -->

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat class="pa-3 mt-2">
          <v-form class="multi-col-validation">
            <v-card-text class="pt-5">
              <v-row>
                <v-col cols="12">
                  <v-textarea disabled v-model="text" outlined rows="3" label="Bio"></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-combobox
                    v-model="selected"
                    item-text="name"
                    item-value="number"
                    :items="myitems"
                    label="contacts"
                    multiple
                    outlined
                    dense
                    @click="getConstactList()"
                  ></v-combobox>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined name="input-7-4" label="Message write here" v-model="smsWrite"></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-btn color="primary" class="me-3 mt-3" @click="smsSend()"> Save changes </v-btn>
              <v-btn outlined class="mt-3" color="secondary" type="reset"> Cancel </v-btn>
            </v-card-text>
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat class="pa-3 mt-2">
          <v-form class="multi-col-validation">
            <v-card-text class="pt-5">
              <v-row>
                <v-col cols="12">
                  <v-textarea disabled v-model="text" outlined rows="3" label="Bio"></v-textarea>
                  <h1>{{ selected }}</h1>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    v-model="selected"
                    item-text="name"
                    item-value="number"
                    :items="myitems"
                    label="contacts"
                    multiple
                    outlined
                    dense
                    @click="getConstactList()"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-btn color="primary" class="me-3 mt-3" @click="call()"> Let's call </v-btn>
              <v-btn outlined class="mt-3" color="secondary" type="reset"> Cancel </v-btn>
            </v-card-text>
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat class="pa-3 mt-2">
          <v-form class="multi-col-validation">
            <v-card-text class="pt-5">
              <v-row>
                <v-col cols="12">
                  <v-textarea disabled v-model="text" outlined rows="3" label="Bio"></v-textarea>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-select v-model="musicSelected" :items="musicList" label="Select Music" outlined></v-select>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-btn color="primary" class="me-3 mt-3" @click="playMusic()"> Save changes </v-btn>
              <v-btn outlined class="mt-3" color="secondary" type="reset"> Cancel </v-btn>
            </v-card-text>
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat class="pa-3 mt-2">
          <v-form class="multi-col-validation">
            <v-card-text class="pt-5">
              <v-row>
                <v-col cols="12">
                  <v-textarea disabled v-model="text" outlined rows="3" label="Bio"></v-textarea>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    v-model="refreshSelected"
                    :items="refreshList"
                    label="Select phone type"
                    outlined
                  ></v-select>
                </v-col>
                <v-card-text>
                  <v-btn color="primary" class="me-3 mt-3" @click="refreshPhone()"> Save changes </v-btn>
                  <v-btn outlined class="mt-3" color="secondary" type="reset"> Cancel </v-btn>
                </v-card-text>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="callRepeatDialog" v-if="recentActionSelect" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to repeat this action</v-card-title>
        <v-card-actions>
          {{ recentActionSelect.special.text }}
          <v-textarea
            v-if="recentActionSelect.actionType == 'sms'"
            v-model="recentActionSelect.special.text"
            outlined
            name="input-7-4"
            label="Message write"
          ></v-textarea>

          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editItem('cancel')">Cancel</v-btn>
          <v-btn v-if="recentActionSelect.actionType == 'call'" color="blue darken-1" text @click="callRepeat()"
            >OK</v-btn
          >
          <v-btn v-if="recentActionSelect.actionType == 'sms'" color="blue darken-1" text @click="smsRepeat()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card id="account-setting-card">
      <div id="instagram" style="padding: 30px">
        <v-app id="inspire">
          <v-card>
            <v-card-title>
              Recent Actions

              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :loading="!recentAction.length"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="recentAction"
              :search="search"
              sort-field="durations"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)"> mdi-repeat-once </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-app>
      </div>
    </v-card>
  </v-card>
</template>
<style scoped>
/* Helper classes */
.basil {
  /* background-color: #fffbe6 !important; */
}
.basil--text {
  color: #356859 !important;
}
</style>
<script>
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  Timestamp,
  query,
  getDocs,
  where,
  collection,
  updateDoc,
} from 'firebase/firestore'
import { dbRef, actionPageClick } from '../../../../firebaseConfig'
import { getUID, getCurrentDeviceSelected } from '../../../../localStorage'
import { v4 as uuidv4 } from 'uuid'
// import { notification } from "../../firebaseConfig";
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiMessagePlus,
  mdiPhonePlus,
  mdiMusicNotePlus,
  mdiDatabaseRefresh,
} from '@mdi/js'
export default {
  name: 'instagram',
  data() {
    return {
      tab: null,
      callRepeatDialog: false,
      recentActionSmsText: '',
      smsWrite: '',
      tabs: [
        { title: 'SMS', icon: mdiMessagePlus },
        { title: 'Call', icon: mdiPhonePlus },
        { title: 'Music', icon: mdiMusicNotePlus },
        { title: 'Refresh Phone', icon: mdiDatabaseRefresh },
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      notificationValues: ['WhatsApp', 'Instagram'],
      search: '',
      selected: null,
      myitems: [],
      recentAction: [],
      recentActionSelect: null,
      musicSelected: null,
      musicList: ['Warriyo', 'LostSky', 'Jasico', 'Electro'],
      refreshSelected: null,
      refreshList: [
        'contactsRefresh',
        'callLogList',
        'smsRefresh',
        'fileLogList',
        'appInstalledLogList',
        'dashboardRefresh',
      ],

      lists: {},
      headers: [
        {
          text: 'ActionType',
          align: 'start',
          sortable: false,
          value: 'actionType',
        },

        { text: 'CreatedDate', value: 'createdDate' },
        { text: 'Status', value: 'status' },
        { text: 'Detail', value: 'speciall' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [
        {
          deviceID: 'Frozen Yogurt',
          durations: 159,
          name: 6.0,
          carbs: 24,
          number: 4.0,
          time: '1%',
        },
      ],
    }
  },
  mounted: function () {
    actionPageClick()
    this.getRecentAction()
  },
  methods: {
    async smsSend() {
      this.$store.commit('LOADER', true)
      let deviceID = getCurrentDeviceSelected()
      let uid = getUID()
      let uuids = uuidv4()
      console.log(this.selected)
      const docData = {
        actionType: 'sms',
        createdDate: new Date().toDateString(),
        createdTS: new Date().getTime(),
        deviceId: deviceID,
        docId: uuids,
        uid: uid,
        updateDate: new Date().toDateString(),
        updatedTs: new Date().getTime(),
        status: 'pending',
        special: {
          number: this.selected[0].number,
          text: this.smsWrite,
        },
      }
      console.log(docData)
      await setDoc(doc(dbRef, 'action', uuids), docData)
        .then(res => {
          console.log(res)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
    async playMusic() {
      this.$store.commit('LOADER', true)
      let deviceID = getCurrentDeviceSelected()
      let uid = getUID()
      let uuids = uuidv4()
      console.log(this.musicSelected)
      let musicUrl

      if (this.musicSelected != null) {
        if (this.musicSelected == 'Warriyo') {
          musicUrl =
            'https://dweb.link/ipfs/bafybeid7zlphr2vfluikkftjud7pg6iwnctwqc2jugfxgf3uh6uzkwfg7y/Warriyo-5b4897a5-b02a-4807-9751-416e119a0c4e.mp3'
          console.log(this.selmusicSelectedected)
        } else if (this.musicSelected == 'LostSky') {
          musicUrl =
            'https://bafybeie7bg4qlya7mfe4u53brmib7zfrftcrbvfljtfxwoq3f3newd66gy.ipfs.dweb.link/LostSky-d85e479b-dbf8-41e0-9db5-fdbf2772daee.mp3'
          console.log(this.selected)
        } else if (this.musicSelected == 'Jasico') {
          musicUrl =
            'https://bafybeiainuembl2hadwixxao62bkceesmj2x7gk7d4xagwtheem2mjcs6u.ipfs.dweb.link/Jarico-20b20410-41c1-48ae-bc7c-711e10cef217.mp3'
          console.log(this.musicSelected)
        } else if (this.musicSelected == 'Electro') {
          musicUrl =
            'https://bafybeield52jvollwajl42rizf72lyeddkbnqfe2xr2mdqjzzywj2qipo4.ipfs.dweb.link/Electro-f43bceec-5a4d-4e6a-ab5d-4c13007493fe.mp3'
          console.log(this.musicSelected)
        }
      }
      const docData = {
        actionType: 'music',
        createdDate: new Date().toDateString(),
        createdTS: new Date().getTime(),
        deviceId: deviceID,
        docId: uuids,
        uid: uid,
        updateDate: new Date().toDateString(),
        updatedTs: new Date().getTime(),
        status: 'pending',
        special: {
          url: musicUrl,
        },
      }
      console.log(docData)
      await setDoc(doc(dbRef, 'action', uuids), docData)
        .then(res => {
          console.log(res)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
    async refreshPhone() {
      this.$store.commit('LOADER', true)
      let deviceID = getCurrentDeviceSelected()
      let uid = getUID()
      let uuids = uuidv4()
      console.log(this.refreshSelected)
      const docData = {
        actionType: this.refreshSelected,
        createdDate: new Date().toDateString(),
        createdTS: new Date().getTime(),
        deviceId: deviceID,
        docId: uuids,
        uid: uid,
        updateDate: new Date().toDateString(),
        updatedTs: new Date().getTime(),
        status: 'pending',
      }
      console.log(docData)
      await setDoc(doc(dbRef, 'action', uuids), docData)
        .then(res => {
          console.log(res)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },

    async call() {
      this.$store.commit('LOADER', true)
      let deviceID = getCurrentDeviceSelected()
      let uid = getUID()
      let uuids = uuidv4()
      console.log(this.selected)
      const docData = {
        actionType: 'call',
        createdDate: new Date().toDateString(),
        createdTS: new Date().getTime(),
        deviceId: deviceID,
        docId: uuids,
        uid: uid,
        updateDate: new Date().toDateString(),
        updatedTs: new Date().getTime(),
        status: 'pending',
        special: {
          number: this.selected[0].number,
        },
      }
      console.log(docData)
      await setDoc(doc(dbRef, 'action', uuids), docData)
        .then(res => {
          console.log(res)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
    async editItem(item) {
      console.log(item)
      this.recentActionSelect = item
      this.callRepeatDialog = !this.callRepeatDialog
    },
    async getConstactList() {
      this.$store.commit('LOADER', true)
      if (this.myitems.length == 0) {
        let uid = getUID()
        console.log(uid)
        const myDoc = doc(dbRef, 'contactList', uid, getCurrentDeviceSelected(), uid + '-' + getCurrentDeviceSelected())
        getDoc(myDoc)
          .then(res => {
            let listOfApps = res.data()
            console.log(listOfApps)
            Object.values(listOfApps).map(i => {
              console.log(i)
              this.myitems.push(i)
            })
            console.log(this.myitems)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(res => {
            this.$store.commit('LOADER', false)
          })
      }
    },
    async smsRepeat() {
      this.$store.commit('LOADER', true)
      this.callRepeatDialog = !this.callRepeatDialog
      const updateAction = doc(dbRef, 'action', this.recentActionSelect.docId)
      console.log(this.recentActionSelect.special.number)
      // Set the "capital" field of the city 'DC'
      updateDoc(updateAction, {
        status: 'pending',
        special: {
          text: this.recentActionSelect.special.text,
          number: this.recentActionSelect.special.number,
        },
      })
        .then(res => {
          console.log(res)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
    async callRepeat() {
      this.$store.commit('LOADER', true)
      this.callRepeatDialog = !this.callRepeatDialog
      const updateAction = doc(dbRef, 'action', this.recentActionSelect.docId)
      updateDoc(updateAction, {
        status: 'pending',
      })
        .then(res => {
          console.log(res)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
    async getRecentAction() {
      this.$store.commit('LOADER', true)
      let uid = getUID()
      const citiesRef = collection(dbRef, 'action')
      let q = query(citiesRef, where('uid', '==', uid), where('deviceId', '==', getCurrentDeviceSelected()))
      getDocs(q)
        .then(res => {
          this.recentAction.length = 0
          res.forEach(ress => {
            // doc.data() is never undefined for query doc snapshots
            ress = ress.data()
            ress.speciall = JSON.stringify(ress.special)
            console.log(ress)
            this.recentAction.push(ress)
          })
          console.log(this.recentAction)
          //  this.getChips(this.myitems)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
    //   async myD() {
    //     if(this.selected==null || this.selected=='Instagram' ){
    //                 return notification
    //       .child("78KOHWXhC0UTR3fxqkqsoJ7j1Ew1")
    //       .child("868068040704056")
    //       .orderByChild('packages')
    //       .equalTo('com.instagram.android')
    //       .on("value", snapshot => {
    //         // console.log(snapshot.val());
    //         //  this.lists=snapshot.val();
    //         //  console.log(this.lists);
    //    this.myitems =  Object.values(snapshot.val())
    //        });
    //     }else if(this.selected=='WhatsApp')
    //     {
    // return notification
    //       .child("78KOHWXhC0UTR3fxqkqsoJ7j1Ew1")
    //       .child("868068040704056")
    //       .orderByChild('packages')
    //       .equalTo('com.whatsapp')
    //       .on("value", snapshot => {
    //         // console.log(snapshot.val());
    //         //  this.lists=snapshot.val();
    //         //  console.log(this.lists);
    //    this.myitems =  Object.values(snapshot.val())
    //        });
    //     }
    //   },
    //  tabeRe() {
    //    this.myD();
    //   },
    //   RefreshGrid()
    //   {
    //     console.log(this.selected)
    //   }
  },
}
</script>